import React from 'react';
import { initialState, useAppContext } from 'utils/AppProvider/AppProvider';

import Button from 'components/Button/Button';
import { type } from 'components/Button/types';

const ResetButton: React.FC = () => {
  const appContext = useAppContext();
  const {
    actions: { clearAll },
  } = appContext;

  const buttonHandleClick = () => {
    if (confirm('Are you sure you want to reset all changes?')) {
      clearAll();
      localStorage.clear();
    }
  };

  const isInitialState = JSON.stringify(appContext.state) === JSON.stringify(initialState);

  return (
    <Button
      disabled={isInitialState}
      onClick={buttonHandleClick}
      text={'Reset all changes'}
      type={type.alert}
    />
  );
};

export default ResetButton;
