import React from 'react';
import { useAppContext } from 'utils/AppProvider/AppProvider';

import Dropdown from 'components/Dropdown/Dropdown';

import type { Option } from './types';

const ThemeChanger: React.FC = () => {
  const appContext = useAppContext();
  const {
    actions: { changeTheme },
    state: { theme },
  } = appContext;

  const handleThemeOptionClick = (value: string) => {
    changeTheme(value);
    localStorage.setItem('theme', value);
  };

  const themeOptions = React.useMemo<Option[]>(
    () => [
      {
        name: 'Dark',
        value: 'dark',
      },
      {
        name: 'Light',
        value: 'light',
      },
    ],
    [],
  );

  React.useEffect(() => {
    const selectedOption = themeOptions.find(item => item.value === theme);
    if (!selectedOption) {
      changeTheme(themeOptions[0].value);
    }
  }, [changeTheme, theme, themeOptions]);

  return (
    <div>
      <h2 className={'sidebar-theme-title'}>Theme:</h2>
      <Dropdown
        handleOptionClick={handleThemeOptionClick}
        options={themeOptions}
        selectedValue={theme}
      />
    </div>
  );
};

export default ThemeChanger;
