import { Options } from './types';

const postMessageToIframe = (iframe?: HTMLIFrameElement, options?: Options) => {
  if (iframe && iframe.contentWindow) {
    const payload: Options = {};
    const { code, colors, theme } = options!;

    if (colors) {
      payload.colors = colors;
    }

    if (theme) {
      payload.theme = theme;
    }

    if (code) {
      payload.code = code;
    }
    if (Object.keys(payload).length > 0) {
      iframe.contentWindow.postMessage(
        {
          payload,
          type: 'STYLES_DATA',
        },
        '*',
      );
    }
  }
};

export default postMessageToIframe;
