import React from 'react';
import cx from 'classnames';

import { ButtonProps } from './types';

import styles from './Button.module.scss';

const Button: React.FC<ButtonProps> = props => {
  const { className, disabled, onClick, text, type } = props;
  return (
    <button
      className={cx(styles.button, styles[type], className)}
      disabled={disabled}
      onClick={onClick}
    >
      {text}
    </button>
  );
};

export default Button;
