import { isEmpty } from '../isEmpty';
import { Colors, CssVariables } from '../types';

export const generateCssVariables = (
  colorsData: Colors,
  mappedVariables: CssVariables,
  themeName = 'light',
) => {
  const variablesData = Object.entries(colorsData)
    .filter(([name]) => name.startsWith(`$${themeName}`))
    .map(item => {
      const cssVariableName = Object.keys(mappedVariables).find(
        key => mappedVariables[key] === item[0],
      );
      return `${cssVariableName}: ${item[1]}`;
    });

  const wrappedData = `html:root${
    themeName !== 'light' ? `[theme=${themeName}]` : ''
  } {\n${variablesData.join(`;\n`)}\n}`;

  return !isEmpty(colorsData) ? wrappedData : '';
};

export default generateCssVariables;
