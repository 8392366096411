import React from 'react';

import Button from 'components/Button/Button';
import { type } from 'components/Button/types';

import { ModalProps } from './types';

import styles from './Modal.module.scss';

const Modal: React.FC<ModalProps> = ({ children, onClose }) => {
  return (
    <div className={styles['modal-wrapper']}>
      <div className={styles['modal-content']}>
        <Button className={styles['modal-close']} onClick={onClose} text={'x'} type={type.alert} />
        {children}
      </div>
    </div>
  );
};

export default Modal;
