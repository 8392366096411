import React, { useState } from 'react';

import Button from 'components/Button/Button';
import { type } from 'components/Button/types';
import CodeEditor from 'components/CodeEditor/CodeEditor';
import Modal from 'components/Modal/Modal';

const CustomStylesButton: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const buttonHandleClick = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button onClick={buttonHandleClick} text={'Edit custom styles'} type={type.primary} />

      {isModalOpen && (
        <Modal onClose={handleModalClose}>
          <CodeEditor />
        </Modal>
      )}
    </>
  );
};

export default CustomStylesButton;
