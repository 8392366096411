import { CssVariables, Variable } from './types';

export const sourceData: Record<string, string> = {
  akira: 'https://nashbet.test.k8s-hz.atlas-iac.com/',
  mobile: 'https://nashbet.m.test.k8s-hz.atlas-iac.com/',
};

const darkBrand: Variable = {
  cssVariableName: '--brand',
  defaultValue: '#4155C6',
  name: 'Dark Brand',
  variableName: '$dark-brand',
};
const darkBrandHover: Variable = {
  cssVariableName: '--brand-hover',
  defaultValue: '#6A7AD2',
  name: 'Dark Brand Hover',
  variableName: '$dark-brand-hover',
};
const darkBrandDisabled: Variable = {
  cssVariableName: '--brand-disabled',
  defaultValue: '#343D72',
  name: 'Dark Brand Disabled',
  variableName: '$dark-brand-disabled',
};
const darkLink: Variable = {
  cssVariableName: '--link',
  defaultValue: '#8E9CF6',
  name: 'Dark Link',
  variableName: '$dark-link',
};
const darkLinkHover: Variable = {
  cssVariableName: '--link-hover',
  defaultValue: '#BDC5FA',
  name: 'Dark Link Hover',
  variableName: '$dark-link-hover',
};
const darkLinkDisabled: Variable = {
  cssVariableName: '--link-disabled',
  defaultValue: '#5C6076',
  name: 'Dark Link Disabled',
  variableName: '$dark-link-disabled',
};
const darkMaterial: Variable = {
  cssVariableName: '--material',
  defaultValue: '#222430',
  name: 'Dark Material',
  variableName: '$dark-material',
};
const darkMaterialHover: Variable = {
  cssVariableName: '--material-hover',
  defaultValue: '#292D42',
  name: 'Dark Material Hover',
  variableName: '$dark-material-hover',
};
const darkMaterialDisabled: Variable = {
  cssVariableName: '--material-disabled',
  defaultValue: '#090A0B',
  name: 'Dark Material Disabled',
  variableName: '$dark-material-disabled',
};
const darkPrimary: Variable = {
  cssVariableName: '--primary',
  defaultValue: '#2D3146',
  name: 'Dark Primary',
  variableName: '$dark-primary',
};
const darkPrimaryHover: Variable = {
  cssVariableName: '--primary-hover',
  defaultValue: '#424866',
  name: 'Dark Primary Hover',
  variableName: '$dark-primary-hover',
};
const darkPrimaryDisabled: Variable = {
  cssVariableName: '--primary-disabled',
  defaultValue: '#1C1E2B',
  name: 'Dark Primary Disabled',
  variableName: '$dark-primary-disabled',
};
const darkBg: Variable = {
  cssVariableName: '-bg',
  defaultValue: '#14151E',
  name: 'Dark Background',
  variableName: '$dark-bg',
};
const darkBorder: Variable = {
  cssVariableName: '--border',
  defaultValue: '#14151E',
  name: 'Dark Border',
  variableName: '$dark-border',
};
const darkShadow: Variable = {
  cssVariableName: '--shadow',
  defaultValue: '#000000',
  name: 'Dark Shadow',
  variableName: '$dark-shadow',
};
const darkTextForMaterial: Variable = {
  cssVariableName: '--text-for-material',
  defaultValue: '#FFFFFF',
  name: 'Dark Text For Material',
  variableName: '$dark-text-for-material',
};
const darkTextForMaterialDisabled: Variable = {
  cssVariableName: '--text-for-material-disabled',
  defaultValue: '#B2B2B2',
  name: 'Dark Text For Material Disabled',
  variableName: '$dark-text-for-material-disabled',
};
const darkSubText: Variable = {
  cssVariableName: '--sub-text',
  defaultValue: '#868C98',
  name: 'Dark Sub Text',
  variableName: '$dark-sub-text',
};
const darkTextForBrand: Variable = {
  cssVariableName: '--text-for-brand',
  defaultValue: '#FFFFFF',
  name: 'Dark Text For Brand',
  variableName: '$dark-text-for-brand',
};
const darkTextForBrandDisabled: Variable = {
  cssVariableName: '--text-for-brand-disabled',
  defaultValue: '#898989',
  name: 'Dark Text For Brand Disabled',
  variableName: '$dark-text-for-brand-disabled',
};
const darkOdd: Variable = {
  cssVariableName: '--odd',
  defaultValue: '#E7CF90',
  name: 'Dark Odd',
  variableName: '$dark-odd',
};
const darkIcon: Variable = {
  cssVariableName: '--icon',
  defaultValue: '#CCCCCC',
  name: 'Dark Icon',
  variableName: '$dark-icon',
};
const darkIconHover: Variable = {
  cssVariableName: '--icon-hover',
  defaultValue: '#FFFFFF',
  name: 'Dark Icon Hover',
  variableName: '$dark-icon-hover',
};
const darkAmber: Variable = {
  cssVariableName: '--amber',
  defaultValue: '#FFB822',
  name: 'Dark Amber',
  variableName: '$dark-amber',
};
const darkRed: Variable = {
  cssVariableName: '--red',
  defaultValue: '#F26F89',
  name: 'Dark Red',
  variableName: '$dark-red',
};
const darkGreen: Variable = {
  cssVariableName: '--green',
  defaultValue: '#01B64F',
  name: 'Dark Green',
  variableName: '$dark-green',
};
const darkDisableButton: Variable = {
  cssVariableName: '--disable-button',
  defaultValue: '#000000',
  name: 'Dark Disable Button',
  variableName: '$dark-disable-button',
};

const lightBrand: Variable = {
  cssVariableName: '--brand',
  defaultValue: '#7280d2',
  name: 'Light Brand',
  variableName: '$light-brand',
};
const lightBrandHover: Variable = {
  cssVariableName: '--brand-hover',
  defaultValue: '#4558C4',
  name: 'Light Brand Hover',
  variableName: '$light-brand-hover',
};
const lightBrandDisabled: Variable = {
  cssVariableName: '--brand-disabled',
  defaultValue: '#A6AFE3',
  name: 'Light Brand Disabled',
  variableName: '$light-brand-disabled',
};
const lightLink: Variable = {
  cssVariableName: '--link',
  defaultValue: '#6173d4',
  name: 'Light Link',
  variableName: '$light-link',
};
const lightLinkHover: Variable = {
  cssVariableName: '--link-hover',
  defaultValue: '#354CC4',
  name: 'Light Link Hover',
  variableName: '$light-link-hover',
};
const lightLinkDisabled: Variable = {
  cssVariableName: '--link-disabled',
  defaultValue: '#8B98DF',
  name: 'Light Link Disabled',
  variableName: '$light-link-disabled',
};
const lightMaterial: Variable = {
  cssVariableName: '--material',
  defaultValue: '#FFFFFF',
  name: 'Light Material',
  variableName: '$light-material',
};
const lightMaterialHover: Variable = {
  cssVariableName: '--material-hover',
  defaultValue: '#f2f4fd',
  name: 'Light Material Hover',
  variableName: '$light-material-hover',
};
const lightMaterialDisabled: Variable = {
  cssVariableName: '--material-disabled',
  defaultValue: '#F6F6F6',
  name: 'Light Material Disabled',
  variableName: '$light-material-disabled',
};
const lightPrimary: Variable = {
  cssVariableName: '--primary',
  defaultValue: '#f2f4fd',
  name: 'Light Primary',
  variableName: '$light-primary',
};
const lightPrimaryHover: Variable = {
  cssVariableName: '--primary-hover',
  defaultValue: '#E2E6F9',
  name: 'Light Primary Hover',
  variableName: '$light-primary-hover',
};
const lightPrimaryDisabled: Variable = {
  cssVariableName: '--primary-disabled',
  defaultValue: '#F2F4FD',
  name: 'Light Primary Disabled',
  variableName: '$light-primary-disabled',
};
const lightBg: Variable = {
  cssVariableName: '--bg',
  defaultValue: '#E3E4EC',
  name: 'Light Background',
  variableName: '$light-bg',
};
const lightBorder: Variable = {
  cssVariableName: '--border',
  defaultValue: '#E3E4EC',
  name: 'Light Border',
  variableName: '$light-border',
};
const lightShadow: Variable = {
  cssVariableName: '--shadow',
  defaultValue: '#DADADC',
  name: 'Light Shadow',
  variableName: '$light-shadow',
};
const lightTextForMaterial: Variable = {
  cssVariableName: '--text-for-material',
  defaultValue: '#464e64',
  name: 'Light Text For Material',
  variableName: '$light-text-for-material',
};
const lightTextForMaterialDisabled: Variable = {
  cssVariableName: '--text-for-material-disabled',
  defaultValue: '#AAB1C4',
  name: 'Light Text For Material Disabled',
  variableName: '$light-text-for-material-disabled',
};
const lightSubText: Variable = {
  cssVariableName: '--sub-text',
  defaultValue: '#848ca0',
  name: 'Light Sub Text',
  variableName: '$light-sub-text',
};
const lightTextForBrand: Variable = {
  cssVariableName: '--text-for-brand',
  defaultValue: '#FFFFFF',
  name: 'Light Text For Brand',
  variableName: '$light-text-for-brand',
};
const lightTextForBrandDisabled: Variable = {
  cssVariableName: '--text-for-brand-disabled',
  defaultValue: '#EBEBEB',
  name: 'Light Text For Brand Disabled',
  variableName: '$light-text-for-brand-disabled',
};
const lightOdd: Variable = {
  cssVariableName: '--odd',
  defaultValue: '#000',
  name: 'Light Odd',
  variableName: '$light-odd',
};
const lightIcon: Variable = {
  cssVariableName: '--icon',
  defaultValue: '#9CA9C6',
  name: 'Light Icon',
  variableName: '$light-icon',
};
const lightIconHover: Variable = {
  cssVariableName: '--icon-hover',
  defaultValue: '#6E7DD9',
  name: 'Light Icon Hover',
  variableName: '$light-icon-hover',
};
const lightAmber: Variable = {
  cssVariableName: '--amber',
  defaultValue: '#FFB822',
  name: 'Light Amber',
  variableName: '$light-amber',
};
const lightRed: Variable = {
  cssVariableName: '--red',
  defaultValue: '#CE0A2E',
  name: 'Light Red',
  variableName: '$light-red',
};
const lightGreen: Variable = {
  cssVariableName: '--green',
  defaultValue: '#219653',
  name: 'Light Green',
  variableName: '$light-green',
};
const lightDisableButton: Variable = {
  cssVariableName: '--disable-button',
  defaultValue: '#FFFFFF',
  name: 'Light Disable Button',
  variableName: '$light-disable-button',
};

const white: Variable = {
  cssVariableName: '--white',
  defaultValue: '#FFFFFF',
  name: 'White',
  variableName: '$white',
};
const amber: Variable = {
  cssVariableName: '--amber',
  defaultValue: '#FFB822',
  name: 'Amber',
  variableName: '$amber',
};
const red: Variable = {
  cssVariableName: '--red',
  defaultValue: '#CE0A2E',
  name: 'Red',
  variableName: '$red',
};
const green: Variable = {
  cssVariableName: '--green',
  defaultValue: '#219653',
  name: 'Green',
  variableName: '$green',
};

export const DARK_VARIABLES: Set<Variable> = new Set([
  darkBrand,
  darkBrandHover,
  darkBrandDisabled,
  darkLink,
  darkLinkHover,
  darkLinkDisabled,
  darkMaterial,
  darkMaterialHover,
  darkMaterialDisabled,
  darkPrimary,
  darkPrimaryHover,
  darkPrimaryDisabled,
  darkBg,
  darkBorder,
  darkShadow,
  darkTextForMaterial,
  darkTextForMaterialDisabled,
  darkSubText,
  darkTextForBrand,
  darkTextForBrandDisabled,
  darkOdd,
  darkIcon,
  darkIconHover,
  darkAmber,
  darkRed,
  darkGreen,
  darkDisableButton,
]);

export const LIGHT_VARIABLES: Set<Variable> = new Set([
  lightBrand,
  lightBrandHover,
  lightBrandDisabled,
  lightLink,
  lightLinkHover,
  lightLinkDisabled,
  lightMaterial,
  lightMaterialHover,
  lightMaterialDisabled,
  lightPrimary,
  lightPrimaryHover,
  lightPrimaryDisabled,
  lightBg,
  lightBorder,
  lightShadow,
  lightTextForMaterial,
  lightTextForMaterialDisabled,
  lightSubText,
  lightTextForBrand,
  lightTextForBrandDisabled,
  lightOdd,
  lightIcon,
  lightIconHover,
  lightAmber,
  lightRed,
  lightGreen,
  lightDisableButton,
]);

export const COMMON_VARIABLES: Set<Variable> = new Set([white, amber, red, green]);

export const DARK_CSS_VARIABLES: CssVariables = {
  '--amber': darkAmber.variableName,
  '--bg': darkBg.variableName,
  '--border': darkBorder.variableName,
  '--brand': darkBrand.variableName,
  '--brand-disabled': darkBrandDisabled.variableName,
  '--brand-hover': darkBrandHover.variableName,
  '--disable-button': darkDisableButton.variableName,
  '--green': darkGreen.variableName,
  '--icon': darkIcon.variableName,
  '--icon-hover': darkIconHover.variableName,
  '--link': darkLink.variableName,
  '--link-disabled': darkLinkDisabled.variableName,
  '--link-hover': darkLinkHover.variableName,
  '--material': darkMaterial.variableName,
  '--material-disabled': darkMaterialDisabled.variableName,
  '--material-hover': darkMaterialHover.variableName,
  '--odd': darkOdd.variableName,
  '--primary': darkPrimary.variableName,
  '--primary-disabled': darkPrimaryDisabled.variableName,
  '--primary-hover': darkPrimaryHover.variableName,
  '--red': darkRed.variableName,
  '--shadow': darkShadow.variableName,
  '--sub-text': darkSubText.variableName,
  '--text-for-brand': darkTextForBrand.variableName,
  '--text-for-brand-disabled': darkTextForBrandDisabled.variableName,
  '--text-for-material': darkTextForMaterial.variableName,
  '--text-for-material-disabled': darkTextForMaterialDisabled.variableName,
};

export const LIGHT_CSS_VARIABLES: CssVariables = {
  '--amber': lightAmber.variableName,
  '--bg': lightBg.variableName,
  '--border': lightBorder.variableName,
  '--brand': lightBrand.variableName,
  '--brand-disabled': lightBrandDisabled.variableName,
  '--brand-hover': lightBrandHover.variableName,
  '--disable-button': lightDisableButton.variableName,
  '--green': lightGreen.variableName,
  '--icon': lightIcon.variableName,
  '--icon-hover': lightIconHover.variableName,
  '--link': lightLink.variableName,
  '--link-disabled': lightLinkDisabled.variableName,
  '--link-hover': lightLinkHover.variableName,
  '--material': lightMaterial.variableName,
  '--material-disabled': lightMaterialDisabled.variableName,
  '--material-hover': lightMaterialHover.variableName,
  '--odd': lightOdd.variableName,
  '--primary': lightPrimary.variableName,
  '--primary-disabled': lightPrimaryDisabled.variableName,
  '--primary-hover': lightPrimaryHover.variableName,
  '--red': lightRed.variableName,
  '--shadow': lightShadow.variableName,
  '--sub-text': lightSubText.variableName,
  '--text-for-brand': lightTextForBrand.variableName,
  '--text-for-brand-disabled': lightTextForBrandDisabled.variableName,
  '--text-for-material': lightTextForMaterial.variableName,
  '--text-for-material-disabled': lightTextForMaterialDisabled.variableName,
};
