import { ColorName } from '../types';

import { Action } from './types';

export const CHANGE_VIEW = 'CHANGE_VIEW';
export const CHANGE_THEME = 'CHANGE_THEME';
export const SET_COLOR = 'SET_COLOR';
export const DELETE_COLOR = 'DELETE_COLOR';
export const SET_CODE = 'SET_CODE';
export const CLEAR_ALL = 'CLEAR_ALL';

export const changeViewAction = (view: string): Action => {
  return {
    payload: {
      view,
    },
    type: CHANGE_VIEW,
  };
};

export const changeThemeAction = (theme: string): Action => {
  return {
    payload: {
      theme,
    },
    type: CHANGE_THEME,
  };
};

export const setColorAction = (color: ColorName, value: string): Action => {
  return {
    payload: {
      color,
      value,
    },
    type: SET_COLOR,
  };
};

export const deleteColorAction = (color: ColorName): Action => {
  return {
    payload: {
      color,
    },
    type: DELETE_COLOR,
  };
};

export const setCodeAction = (code: string): Action => {
  return {
    payload: {
      code,
    },
    type: SET_CODE,
  };
};

export const clearAllAction = () => ({
  payload: null,
  type: CLEAR_ALL,
});
