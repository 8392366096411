import React from 'react';
import MonacoEditor, { EditorDidMount, EditorWillUnmount } from 'react-monaco-editor';
import { useAppContext } from 'utils/AppProvider/AppProvider';

const CodeEditor: React.FC = () => {
  const appContext = useAppContext();
  const {
    actions: { setCode },
    state: { code },
  } = appContext;
  const [editorCode, setEditorCode] = React.useState<string>(code);

  const editorDidMount: EditorDidMount = editor => {
    editor.focus();
  };

  const editorWillUnmount: EditorWillUnmount = React.useCallback(
    editor => {
      setCode(editor.getValue());
      localStorage.setItem('code', JSON.stringify(editor.getValue()));
    },
    [setCode],
  );

  const onChange = (newValue: string) => {
    setEditorCode(newValue);
  };

  const options = {
    selectOnLineNumbers: true,
  };

  return (
    <MonacoEditor
      editorDidMount={editorDidMount}
      editorWillUnmount={editorWillUnmount}
      height="400"
      language="scss"
      onChange={onChange}
      options={options}
      theme={'vs-dark'}
      value={editorCode}
      width="800"
    />
  );
};

export default CodeEditor;
