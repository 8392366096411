import React from 'react';
import { useAppContext } from 'utils/AppProvider/AppProvider';
import { sourceData } from 'utils/constants';

import Frame from 'components/Frame/Frame';
import Sidebar from 'components/Sidebar/Sidebar';

import styles from './Page.module.scss';

const Page: React.FC = () => {
  const appContext = useAppContext();
  const {
    state: { view },
  } = appContext;
  const [source, setSource] = React.useState<string>(sourceData[view]);

  React.useEffect(() => {
    setSource(sourceData[view]);
  }, [view]);

  return (
    <div className={styles['page-content']}>
      <div className={styles['frame-wrapper']}>
        <Frame src={source} />
      </div>

      <div className={styles['sidebar-wrapper']}>
        <Sidebar />
      </div>
    </div>
  );
};

export default Page;
