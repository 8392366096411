import React from 'react';

import type { DropdownProps } from './types';

import styles from './Dropdown.module.scss';

const Dropdown: React.FC<DropdownProps> = props => {
  const [active, setActive] = React.useState<boolean>(false);
  const { handleOptionClick, options, selectedValue } = props;

  const selectedItem = React.useMemo(() => {
    return options.find(item => item.value === selectedValue);
  }, [options, selectedValue]);

  const handleClick = React.useCallback(() => {
    setActive(prevState => !prevState);
  }, []);

  return (
    <div className={styles['dropdown']}>
      <button className={styles['dropdown-button']} onClick={handleClick}>
        {selectedItem?.name}
      </button>
      {active && (
        <div className={styles['dropdown-content']} data-testid={'dropdown-content'}>
          {options.map(option => {
            const { name, value } = option;
            return (
              <button
                className={'dropdown-option'}
                key={name}
                onClick={() => handleOptionClick(value)}
              >
                {name}
              </button>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
