export enum type {
  primary = 'primary',
  secondary = 'secondary',
  success = 'success',
  alert = 'alert',
}

export interface ButtonProps {
  text: string;
  type: type;
  onClick: () => void;
  disabled?: boolean;
  className?: string;
}
