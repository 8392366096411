import React from 'react';
import { useAppContext } from 'utils/AppProvider/AppProvider';
import { debounce } from 'utils/debounce';

import type { ColorFieldProps } from './types';

import styles from './ColorField.module.scss';

export const setNewColor = debounce((id, value, colors, deleteColor, setColor) => {
  if (Object.keys(colors).includes(id)) {
    deleteColor(id);
  }
  setColor(id, value);
});

export const ColorField: React.FC<ColorFieldProps> = props => {
  const appContext = useAppContext();
  const {
    actions: { deleteColor, setColor },
    state: { colors },
  } = appContext;
  const { color, id, name, onChange } = props;

  React.useEffect(() => {
    localStorage.setItem('colors', JSON.stringify(colors));
  }, [colors]);

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { id, value } = event.target;

      setNewColor(id, value, colors, deleteColor, setColor);

      if (onChange) {
        onChange(value);
      }
    },
    [onChange, colors, deleteColor, setColor],
  );

  return (
    <div className={styles['color-field']}>
      <label className={styles['color-field-label']} htmlFor={id} title={name}>
        {name}
      </label>
      <input
        className={styles['color-field-input']}
        data-testid={'color-input'}
        id={id}
        onChange={handleChange}
        type={'color'}
        value={colors[id] || color}
      />
    </div>
  );
};

export default ColorField;
