import React, { useEffect } from 'react';
import { useAppContext } from 'utils/AppProvider/AppProvider';

import Dropdown from 'components/Dropdown/Dropdown';
import { Option } from 'components/ViewChanger/types';

const ThemeChanger: React.FC = () => {
  const appContext = useAppContext();
  const {
    actions: { changeView },
    state: { view },
  } = appContext;

  const viewOptions = React.useMemo<Option[]>(
    () => [
      {
        name: 'Akira',
        value: 'akira',
      },
      {
        name: 'Mobile',
        value: 'mobile',
      },
    ],
    [],
  );

  const handleViewOptionClick = React.useCallback(
    (value: string) => {
      changeView(value);
      localStorage.setItem('view', value);
    },
    [changeView],
  );

  useEffect(() => {
    const selectedOption = viewOptions.find(item => item.value === view);
    if (!selectedOption) {
      changeView(viewOptions[0].value);
    }
  }, [view, viewOptions, changeView]);

  return (
    <div>
      <h2 className={'sidebar-view-title'}>Site view:</h2>
      <Dropdown
        handleOptionClick={handleViewOptionClick}
        options={viewOptions}
        selectedValue={view}
      />
    </div>
  );
};

export default ThemeChanger;
