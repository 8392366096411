import React from 'react';

import { ColorName } from '../types';

import {
  CHANGE_THEME,
  CHANGE_VIEW,
  changeThemeAction,
  changeViewAction,
  CLEAR_ALL,
  clearAllAction,
  DELETE_COLOR,
  deleteColorAction,
  SET_CODE,
  SET_COLOR,
  setCodeAction,
  setColorAction,
} from './actions';
import { Action, AppContextType, AppProviderProps, AppState } from './types';

const getColorsFromLocalStorage = () => {
  const colors = localStorage.getItem('colors');
  return colors ? JSON.parse(colors) : {};
};

const getCodeFromLocalStorage = () => {
  const code = localStorage.getItem('code');
  return code ? JSON.parse(code) : ``;
};

export const defaultInitialState: AppState = {
  code: getCodeFromLocalStorage(),
  colors: getColorsFromLocalStorage(),
  theme: localStorage.getItem('theme') || 'light',
  view: localStorage.getItem('view') || 'akira',
};

export const getDefaultInitialState = (): AppState => ({
  code: getCodeFromLocalStorage(),
  colors: getColorsFromLocalStorage(),
  theme: localStorage.getItem('theme') || 'light',
  view: localStorage.getItem('view') || 'akira',
});

export const initialState: AppState = {
  code: ``,
  colors: {},
  theme: 'dark',
  view: 'akira',
};

export const appReducer = (state: AppState, action: Action): AppState => {
  switch (action.type) {
    case CHANGE_VIEW:
      return {
        ...state,
        view: action.payload.view,
      };
    case CHANGE_THEME:
      return {
        ...state,
        theme: action.payload.theme,
      };
    case SET_COLOR:
      return {
        ...state,
        colors: { ...state.colors, [action.payload.color]: action.payload.value },
      };
    case DELETE_COLOR: {
      const { [action.payload.color]: deletedColor, ...restColors } = state.colors;
      return {
        ...state,
        colors: restColors,
      };
    }
    case SET_CODE:
      return {
        ...state,
        code: action.payload.code,
      };
    case CLEAR_ALL:
      return { ...initialState };

    default:
      return state;
  }
};

export const AppContext = React.createContext<AppContextType | null>(null);

export const AppProvider: React.FC<AppProviderProps> = ({
  children,
  initialState = getDefaultInitialState(),
}) => {
  const [state, dispatch] = React.useReducer(appReducer, initialState);

  const appContextValue: AppContextType = {
    actions: {
      changeTheme: (theme: string) => dispatch(changeThemeAction(theme)),
      changeView: (view: string) => dispatch(changeViewAction(view)),
      clearAll: () => dispatch(clearAllAction()),
      deleteColor: (color: ColorName) => dispatch(deleteColorAction(color)),
      setCode: (code: string) => dispatch(setCodeAction(code)),
      setColor: (color: ColorName, value: string) => dispatch(setColorAction(color, value)),
    },
    state,
  };

  return <AppContext.Provider value={appContextValue}>{children}</AppContext.Provider>;
};

export const useAppContext = (): AppContextType => {
  const appContext = React.useContext(AppContext);
  if (!appContext || !appContext.state) {
    throw new Error('useAppContext must be used within an AppProvider');
  }
  return appContext;
};
