import { Colors, Variable } from '../types';

const generateScssVariablesToExport = (variables: Variable[], contextColors: Colors): string => {
  const variablesData = variables.reduce(
    (acc: string[], item: { variableName: string; defaultValue: string }) => {
      const contextColorAvailable = Object.keys(contextColors).includes(item.variableName);
      const newColorValue = contextColors[item.variableName];
      const variable = `${item.variableName}: ${
        contextColorAvailable ? newColorValue : item.defaultValue
      }`;
      acc.push(variable);
      return acc;
    },
    [],
  );
  return variablesData.join(`;\n`).concat(';');
};

export default generateScssVariablesToExport;
