import React from 'react';

import CustomStylesButton from 'components/CustomStylesButton/CustomStylesButton';
import ExportButton from 'components/ExportButton/ExportButton';
import ResetButton from 'components/ResetButton/ResetButton';

import styles from './Buttons.module.scss';

const Buttons: React.FC = () => {
  return (
    <div className={styles['buttons-block']}>
      <ResetButton />
      <CustomStylesButton />
      <ExportButton />
    </div>
  );
};

export default Buttons;
