import { CssVariables } from '../types';

const generateCssVariablesToExport = (variables: CssVariables, themeName?: string): string => {
  const variablesData = Object.keys(variables).map(item => {
    const value = Object.values(variables).find(value => variables[item] === value);
    const variable = `${item}: #{${value}}`;
    return variable;
  });

  const wrappedData = `html:root${themeName ? `[theme=${themeName}]` : ''} {\n${variablesData
    .join(`;\n`)
    .concat(';')}\n}`;

  return wrappedData;
};

export default generateCssVariablesToExport;
