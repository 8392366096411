import React from 'react';

import Buttons from 'components/Buttons/Buttons';
import ColorFields from 'components/ColorFields/ColorFields';
import ThemeChanger from 'components/ThemeChanger/ThemeChanger';
import ViewChanger from 'components/ViewChanger/ViewChanger';

import styles from './Sidebar.module.scss';

const Sidebar: React.FC = () => {
  return (
    <aside className={styles['sidebar']}>
      <ViewChanger />
      <ThemeChanger />
      <ColorFields />
      <Buttons />
    </aside>
  );
};

export default Sidebar;
