import React from 'react';
import { AppProvider } from 'utils/AppProvider/AppProvider';
import { getDefaultInitialState } from 'utils/AppProvider/AppProvider';

import Page from 'components/Page/Page';

const App = () => {
  return (
    <AppProvider initialState={getDefaultInitialState()}>
      <Page />
    </AppProvider>
  );
};

export default App;
