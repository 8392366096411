import React, { FC, useCallback, useEffect, useRef } from 'react';
import { useAppContext } from 'utils/AppProvider/AppProvider';
import { DARK_CSS_VARIABLES, LIGHT_CSS_VARIABLES } from 'utils/constants';
import generateCssVariables from 'utils/generateCssVariables/generateCssVariables';
import postMessageToIframe from 'utils/postMessageToIframe/postMessageToIframe';

import type { FrameProps } from './types';

import styles from './Frame.module.scss';

const Frame: FC<FrameProps> = props => {
  const { src } = props;
  const appContext = useAppContext();
  const {
    state: { code, colors, theme },
  } = appContext;

  const setRef = useCallback((node: HTMLIFrameElement) => {
    if (node) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      frameRef.current = node;
    }
  }, []);

  const frameRef = useRef<HTMLIFrameElement>(null);

  const updateData = useCallback(() => {
    const iframe = (frameRef || {}).current;

    if (!iframe) return;

    let generatedColors = '';

    if (colors) {
      const darkColors = generateCssVariables(colors, DARK_CSS_VARIABLES, 'dark');
      const lightColors = generateCssVariables(colors, LIGHT_CSS_VARIABLES);
      generatedColors += darkColors + lightColors;
    }

    postMessageToIframe(iframe, { code: code, colors: generatedColors, theme: theme });
  }, [code, colors, theme]);

  useEffect(() => updateData(), [updateData]);

  return (
    <iframe
      className={styles['frame']}
      id={src}
      key={src}
      onLoad={updateData}
      ref={setRef}
      src={src}
      title="embedded content"
    />
  );
};

export default Frame;
