import {
  COMMON_VARIABLES,
  DARK_CSS_VARIABLES,
  DARK_VARIABLES,
  LIGHT_CSS_VARIABLES,
  LIGHT_VARIABLES,
} from '../constants';
import generateCssVariablesToExport from '../generateCssVariablesToExport/generateCssVariablesToExport';
import generateScssVariablesToExport from '../generateScssVariablesToExport/generateScssVariablesToExport';
import { Colors } from '../types';

const generateFullScssFileData = (colors: Colors) => {
  const generateDarkVariables = generateScssVariablesToExport(Array.from(DARK_VARIABLES), colors);
  const generateLightVariables = generateScssVariablesToExport(Array.from(LIGHT_VARIABLES), colors);
  const generateCommonVariables = generateScssVariablesToExport(
    Array.from(COMMON_VARIABLES),
    colors,
  );
  const generateLightCssVariables = generateCssVariablesToExport(LIGHT_CSS_VARIABLES);
  const generateDarkCssVariables = generateCssVariablesToExport(DARK_CSS_VARIABLES, 'dark');

  return `${generateDarkVariables}\n\n${generateLightVariables}\n\n${generateCommonVariables}\n\n\n${generateLightCssVariables}\n\n${generateDarkCssVariables}\n`;
};

export default generateFullScssFileData;
