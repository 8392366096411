import React from 'react';
import { useAppContext } from 'utils/AppProvider/AppProvider';
import { COMMON_VARIABLES, DARK_VARIABLES, LIGHT_VARIABLES } from 'utils/constants';
import { Variable } from 'utils/types';

import ColorField from 'components/ColorField/ColorField';

import styles from './ColorFields.module.scss';

const ColorFields: React.FC = () => {
  const appContext = useAppContext();
  const {
    state: { colors, theme },
  } = appContext;

  const commonColorsData = Array.from(COMMON_VARIABLES);
  const themeColorsData = Array.from(theme === 'dark' ? DARK_VARIABLES : LIGHT_VARIABLES);

  const resultData = themeColorsData.concat(commonColorsData);

  const fieldColor = React.useMemo(
    () => (item: Variable) => {
      return Object.keys(colors).includes(item.variableName)
        ? colors[item.variableName]
        : item.defaultValue;
    },
    [colors],
  );

  return (
    <div className={styles['color-fields']}>
      <h2 className={styles['color-fields-title']}>Colors:</h2>
      {resultData.map(item => (
        <ColorField
          color={fieldColor(item)}
          id={item.variableName}
          key={item.variableName}
          name={item.name}
        />
      ))}
    </div>
  );
};

export default ColorFields;
