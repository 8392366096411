import React from 'react';
import { FileSaverOptions, saveAs } from 'file-saver';
import { useAppContext } from 'utils/AppProvider/AppProvider';
import generateFullScssFileData from 'utils/generateFullScssFileData/generateFullScssFileData';

import Button from 'components/Button/Button';
import { type } from 'components/Button/types';

const ExportButton: React.FC = () => {
  const appContext = useAppContext();
  const {
    state: { colors },
  } = appContext;
  const resultData = generateFullScssFileData(colors);
  const options: FileSaverOptions = {
    autoBom: true,
  };

  const handleButtonClick = () => {
    const blob = new Blob([resultData], {
      endings: 'native',
      type: 'text/scss;charset=utf-8',
    });
    saveAs(blob, 'styles.scss', options);
  };

  return <Button onClick={handleButtonClick} text={'Export data'} type={type.success} />;
};

export default ExportButton;
