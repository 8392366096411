export const isEmpty = (obj: unknown): boolean => {
  if (!obj) {
    return true;
  }
  if (typeof obj !== 'object') {
    return false;
  }
  if (Array.isArray(obj)) {
    return obj.length === 0;
  }
  return Object.keys(obj).length === 0;
};
